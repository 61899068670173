// We haven't added icon's computed property because it makes this mixin coupled with UI
export const togglePasswordVisibility = {
  data() {
    return {
      passwordFieldType: "password",
      confirmPasswordFieldType: "password",
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    toggleConfirmPasswordVisibility() {
      this.confirmPasswordFieldType =
        this.confirmPasswordFieldType === "password" ? "text" : "password";
    },
  },
};

export const toggleConfirmPasswordVisibility = {
  data() {
    return {
      confirmPasswordFieldType: "password",
    };
  },
  methods: {
    toggleConfirmPasswordVisibility() {
      this.confirmPasswordFieldType =
        this.confirmPasswordFieldType === "password" ? "text" : "password";
    },
  },
};

export const _ = null;
